import React from 'react'

function About() {
  return (
    <div>
      <h1 className='text-5xl font-bold font-Montserrat max-md:text-3xl mb-3'>About Us</h1>
      <p>Welcome to FX Movies! We are a vibrant online community dedicated to bringing you the latest news, reviews, and updates from the world of entertainment. Our team of passionate writers and editors work tirelessly to provide you with engaging content that covers movies, television shows, music, and more. By exploring our website, you will discover exclusive interviews, insightful articles, and exciting sneak peeks into your favorite shows and movies. Join us on this journey as we celebrate the magic of entertainment together.</p>
    </div>
  )
}

export default About
