import React, { useEffect, useState } from "react";
import UAParser from "ua-parser-js";

const Download = () => {
  const [cpuArch, setCpuArch] = useState(null);

  useEffect(() => {
    const getArchitecture = () => {
      const parser = new UAParser();
      const { architecture } = parser.getCPU();
      const platform = navigator.platform;

      console.log("Detected architecture:", architecture);
      console.log("Platform:", platform);

      // Check for various platforms and map them to architecture types
      if (
        platform.includes("Linux armv81") ||
        platform.includes("Linux aarch64")
      ) {
        return "arm64-v8a"; // Linux armv81 and aarch64 map to arm64-v8a
      }
      if (platform.includes("Linux armv7") || platform.includes("armv7l")) {
        return "armeabi-v7a"; // Common ARMv7 platforms
      }
      if (platform.includes("Linux i686") || platform.includes("i386")) {
        return "x86"; // x86 platforms
      }
      if (
        platform.includes("Linux x86_64") ||
        architecture === "x86_64" ||
        platform.includes("Win64")
      ) {
        return "x86_64"; // x86_64 platforms
      }
      if (/arm64|aarch64/i.test(platform)) {
        return "arm64-v8a"; // General check for ARM 64-bit
      }

      // Fallback to detected architecture or unknown
      console.log("Fallback architecture:", architecture || "unknown");
      return architecture || "unknown";
    };

    setCpuArch(getArchitecture());
  }, []);

  return (
    <div className="relative w-full h-full ">
      {/* {cpuArch ? (
                <p>CPU Architecture: {cpuArch}</p>
            ) : (
                <p>Loading CPU information...</p>
            )} */}
      <div className="flex items-center justify-between z-10 max-md:flex-col">
        <div className="w-[40%] max-md:w-full flex flex-col gap-7 relative py-10">
          <h1 className="text-5xl max-sm:text-3xl font-bold font-Montserrat">
            Get the Best Movie Streaming App
          </h1>
          <img
            src="https://cdn-icons-png.flaticon.com/512/10238/10238354.png"
            alt=""
            className="absolute  top-0 right-20 z-[-1] blur-2xl w-40 aspect-square"
          />
          <p className="font-Outfit text-slate-600">
            Discover thousands of movies, series, and TV shows all in one place.
            Get access to thousands of movies, series, and TV shows at your
            fingertips.
          </p>
          {cpuArch == "x86_64" && (
            <a href="https://dl1.moview.site/FX-Movies-x86_64.apk">
              <button className="flex w-full items-center justify-center bg-gradient-to-r from-blue-300 to-blue-500 py-3 px-8 max-sm:px-5 max-sm:py-1 text-white font-Montserrat tracking-widest">
                Download{" "}
                <img
                  src="https://static.thenounproject.com/png/1337191-200.png"
                  alt=""
                  className="h-8 invert ml-2"
                />
              </button>
            </a>
          )}
          {cpuArch == "arm64-v8a" && (
            <a href="https://dl1.moview.site/FX-Movies-arm64-v8a.apk">
              <button className="flex w-full items-center justify-center bg-gradient-to-r from-blue-300 to-blue-500 py-3 px-8 max-sm:px-5 max-sm:py-1 text-white font-Montserrat tracking-widest">
                Download{" "}
                <img
                  src="https://static.thenounproject.com/png/1337191-200.png"
                  alt=""
                  className="h-8 invert ml-2"
                />
              </button>
            </a>
          )}
          {cpuArch == "armeabi-v7a" && (
            <a href="https://dl1.moview.site/FX-Movies-armeabi-v7a.apk">
              <button className="flex w-full items-center justify-center bg-gradient-to-r from-blue-300 to-blue-500 py-3 px-8 max-sm:px-5 max-sm:py-1 text-white font-Montserrat tracking-widest">
                Download{" "}
                <img
                  src="https://static.thenounproject.com/png/1337191-200.png"
                  alt=""
                  className="h-8 invert ml-2"
                />
              </button>
            </a>
          )}
          {cpuArch == "x86" && (
            <a href="https://dl1.moview.site/FX-Movies-x86.apk">
              <button className="flex w-full items-center justify-center bg-gradient-to-r from-blue-300 to-blue-500 py-3 px-8 max-sm:px-5 max-sm:py-1 text-white font-Montserrat tracking-widest">
                Download{" "}
                <img
                  src="https://static.thenounproject.com/png/1337191-200.png"
                  alt=""
                  className="h-8 invert ml-2"
                />
              </button>
            </a>
          )}
          {cpuArch == "unknown" && (
            <a href="https://dl1.moview.site/FX-Movies-universal.apk">
              <button className="flex w-full items-center justify-center bg-gradient-to-r from-blue-300 to-blue-500 py-3 px-8 max-sm:px-5 max-sm:py-1 text-white font-Montserrat tracking-widest">
                Download{" "}
                <img
                  src="https://static.thenounproject.com/png/1337191-200.png"
                  alt=""
                  className="h-8 invert ml-2"
                />
              </button>
            </a>
          )}
          {cpuArch != "x86_64" &&
            cpuArch != "arm64-v8a" &&
            cpuArch != "armeabi-v7a" &&
            cpuArch != "x86" &&
            cpuArch != "unknown" && (
              <a href="https://dl1.moview.site/FX-Movies-universal.apk">
                <button className="flex w-full items-center justify-center bg-gradient-to-r from-blue-300 to-blue-500 py-3 px-8 max-sm:px-5 max-sm:py-1 text-white font-Montserrat tracking-widest">
                  Download{" "}
                  <img
                    src="https://static.thenounproject.com/png/1337191-200.png"
                    alt=""
                    className="h-8 invert ml-2"
                  />
                </button>
              </a>
            )}
        </div>
        <div className="w-[60%] max-md:w-full p-6 flex justify-center items-center">
          <img
            src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiJWrRN0QmaaoQxA2l-jq4fExwr-NJnm3pZM3foXBn6LomskcRcZ4rso1vWbN6g6fJAwYKrBTQn9K53idhqOCmLvvgKjUppzvEJp4m2MBrMZeu1YjrV-lxV7B4xw8ubq46jXQM6lIHwJQTxWXFbI9SBbHA0Fk-VRCGHqFook6PoKIombNRxsQtdy_onXfgo/s1097/Untitled%20design%20-%202024-09-04T193508.255.png"
            alt=""
            className="h-full max-h-[700px] object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default Download;
