import React from 'react'

function Navbar() {
  return (
    <div className='w-full font-Outfit py-3 flex justify-between items-center'>
      <div className="logo">
        <h1 className='text-3xl font-bold font-Raleway font-Handjet'>FX Movies</h1>
      </div>
      <ul className='flex gap-10 max-sm:gap-4'>
        <a href="#"><li>Home</li></a>
        <a href="about-us"><li>About Us</li></a>
        <a href="#"><li>Contact Us</li></a>
      </ul>
    </div>
  )
}

export default Navbar
