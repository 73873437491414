import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Download from "./pages/download";
import About from "./pages/about";
import Navbar from "./components/navbar";

function App() {
  return (
    <div className="px-36 max-lg:px-24 max-sm:px-2 font-Outfit">
      <Navbar />
      <Routes>
      <Route path="/" element={<Download />} />
      <Route path="/download" element={<Download />} />
      <Route path="/about-us" element={<About />} />
    </Routes>
    </div>
  );
}

export default App;
